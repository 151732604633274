import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../common/Footer";
import Sidebar from "../../common/Sidebar";
import Navigation from "../../common/Navigation";
import CommonService from "../../../services/CommonService";
import Swal from "sweetalert2";
import Select from 'react-select';
import "../../../App.css";

export default function Create() {
    const nav = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [selectedRc, setSelectedRc] = useState(null);
    const formRef = useRef(null);
    const clientID = localStorage.getItem('id');

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
       
        if (selectedFile && (selectedFile.type === 'text/csv' || 
            selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 
            selectedFile.name.endsWith('.csv') || selectedFile.name.endsWith('.xlsx'))) {
            setFile(selectedFile);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Invalid File Type',
                text: 'Please upload a valid CSV or XLSX file.',
            });
            setFile(null);
            e.target.value = null; // Reset the input
        }
    };

   
    const productFormSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            Swal.fire({
                icon: 'error',
                title: 'No File Selected',
                text: 'Please select a CSV or XLSX file to upload.',
            });
            return;
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('client_id', clientID);
        formData.append('service_type', selectedRc);
        formData.append('importFile', file);

        try {
           
            const response = await CommonService.bulkImport(formData);
            if (response.data.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.message,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Upload Failed',
                    text: response.data.message,
                });
            }
        } catch (error) {
            console.error('An error occurred:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response?.data?.message || 'An error occurred while uploading the file. Please try again.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        formRef.current.reset();
        setSelectedRc(null);
        setFile(null);
    };

    const rcData = [
        { value: 'rc_advance', label: 'RC Advance' },
        //{ value: 'rc_normal', label: 'RC Normal' },
        { value: 'rc_reverse', label: 'RC Reverse' },
        // { value: 'rc_echallan', label: 'Echallan Advance' },
        // { value: 'rc_advance_plus', label: 'RC Advance Plus' },
        // { value: 'rc_echallan_plus', label: 'Echallan Advance Plus' },
    ];

    const sampleFiles = {
        'rc_advance': 'assets/sample-file/rc-advance-sample.xlsx',
       // 'rc_advance_plus': 'assets/sample-file/rc-advance-sample.xlsx',
       // 'rc_normal': 'assets/sample-file/rc-normal-sample.xlsx',
        'rc_reverse': 'assets/sample-file/rc-reverse-sample.xlsx',
       // 'rc_echallan': 'assets/sample-file/e-challan-advance.xlsx',
       // 'rc_echallan_plus': 'assets/sample-file/e-challan-advance.xlsx',
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar />
                <div className="layout-page">
                    <Navigation />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="card">
                                <div className="row">
                                    <div className="col-md-7">
                                        <h5 className="card-header">Import File</h5>
                                    </div>
                                </div>
                                {isLoading ? (
                                    <div className="d-flex justify-content-center">
                                        <div className="sk-fold sk-primary">
                                            <div className="sk-fold-cube"></div>
                                            <div className="sk-fold-cube"></div>
                                            <div className="sk-fold-cube"></div>
                                            <div className="sk-fold-cube"></div>
                                        </div>
                                    </div>
                                ) : (
                                    <form className="card-body" ref={formRef} style={{ paddingTop: "0px" }} data-parsley-validate="" onSubmit={productFormSubmit}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="multicol-rc-type">Select RC Type</label>
                                                <Select
                                                    options={rcData}
                                                    placeholder="Select Type"
                                                    onChange={(selectedOption) => setSelectedRc(selectedOption.value)}
                                                    value={rcData.find((option) => option.value === selectedRc)}
                                                    required
                                                    data-parsley-required="true"
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="multicol-file-upload">Upload File (CSV or XLSX)</label>
                                                <input type="file" onChange={handleFileChange} accept=".csv, .xlsx" required data-parsley-required="true" className="form-control" />
                                            </div>
                                            <div className="col-md-4 d-flex align-items-end">
                                                <a 
                                                    href={selectedRc ? sampleFiles[selectedRc] : "#"} 
                                                    className={`btn btn-info ${!selectedRc ? 'disabled' : ''}`} 
                                                    download
                                                  style={{textDecoration:"none"}}
                                                >
                                                    Download Sample File
                                                </a>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="pt-4">
                                            <button type="submit" className="btn btn-primary me-sm-3 me-1">Import File</button>
                                            <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                        <Footer />
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>
    );
}
