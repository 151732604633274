import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../common/Footer";
import Sidebar from "../../common/Sidebar";
import Navigation from "../../common/Navigation";
import CommonService from "../../../services/CommonService";
import Swal from "sweetalert2";
import Select from 'react-select';
import "../../../App.css";

export default function Create() {
    const nav = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRc, setSelectedRc] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState('');
    const formRef = useRef(null);
    const clientID = localStorage.getItem('id');

    // const validateDates = () => {
    //     if (new Date(toDate) <= new Date(fromDate)) {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Invalid Date Range',
    //             text: 'To Date must be greater than From Date.',
    //         });
    //         return false;
    //     }
    //     return true;
    // };

    const productFormSubmit = async (event) => {
        event.preventDefault();
        //if (!validateDates()) return;

        setIsLoading(true);
        try {
            const data = {
                client_id:clientID,
                service_type: selectedRc,
                fromData: fromDate,
                toDate: toDate,
            };
            const response = await CommonService.exportFile(data); 
            if (response.data.status === 200) {
                setShowDownloadButton(true);
                setDownloadUrl(response.data.data); 
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.message,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Request Failed',
                    text: response.data.message,
                });
            }
        } catch (error) {
            console.error('An error occurred:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response?.data?.message || 'An error occurred. Please try again.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownload = async () => {
        if (downloadUrl) {
            try {
                const data = {
                    filename: downloadUrl
                };
                const response = await CommonService.downloadFile(data); 
    
                if (response.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', downloadUrl); 
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
    
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'File downloaded successfully',
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Request Failed',
                        text: 'Failed to download file',
                    });
                }
            } catch (error) {
                console.error('An error occurred:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.response?.data?.message || 'An error occurred. Please try again.',
                });
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Download Error',
                text: 'No file available for download.',
            });
        }
    };
    

    const handleCancel = () => {
        formRef.current.reset();
        setSelectedRc(null);
        setFromDate('');
        setToDate('');
        setShowDownloadButton(false);
        setDownloadUrl('');
    };

    const rcData = [
        { value: 'rc_advance', label: 'RC Advance' },
        //{ value: 'rc_normal', label: 'RC Normal' },
        { value: 'rc_reverse', label: 'RC Reverse' },
        // { value: 'rc_echallan', label: 'Echallan Advance' },
        // { value: 'rc_advance_plus', label: 'RC Advance Plus' },
        // { value: 'rc_echallan_plus', label: 'Echallan Advance Plus' }
    ];

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar />
                <div className="layout-page">
                    <Navigation />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="card">
                                <div className="row">
                                    <div className="col-md-7">
                                        <h5 className="card-header">Export File (Download File)</h5>
                                    </div>
                                </div>
                                {isLoading ? (
                                    <div className="d-flex justify-content-center">
                                        <div className="sk-fold sk-primary">
                                            <div className="sk-fold-cube"></div>
                                            <div className="sk-fold-cube"></div>
                                            <div className="sk-fold-cube"></div>
                                            <div className="sk-fold-cube"></div>
                                        </div>
                                    </div>
                                ) : (
                                    <form className="card-body" ref={formRef} style={{ paddingTop: "0px" }} data-parsley-validate="" onSubmit={productFormSubmit}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="multicol-rc-type">Select RC Type</label>
                                                <Select
                                                    options={rcData}
                                                    placeholder="Select Type"
                                                    onChange={(selectedOption) => setSelectedRc(selectedOption.value)}
                                                    value={rcData.find((option) => option.value === selectedRc)}
                                                    required
                                                    data-parsley-required="true"
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="multicol-from-date">From Date</label>
                                                <input 
                                                    type="date" 
                                                    id="multicol-from-date"
                                                    value={fromDate}
                                                    onChange={(e) => setFromDate(e.target.value)}
                                                    required 
                                                    data-parsley-required="true" 
                                                    className="form-control" 
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="multicol-to-date">To Date</label>
                                                <input 
                                                    type="date" 
                                                    id="multicol-to-date"
                                                    value={toDate}
                                                    onChange={(e) => setToDate(e.target.value)}
                                                    required 
                                                    data-parsley-required="true" 
                                                    className="form-control" 
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div className="pt-4">
                                            <button type="submit" className="btn btn-primary me-sm-3 me-1">Export Data</button>
                                            {showDownloadButton && (
                                                <button type="button" className="btn btn-success me-sm-3 me-1" onClick={handleDownload}>Download</button>
                                            )}
                                            <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                        <Footer />
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>
    );
}
