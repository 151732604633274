import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Sidebar = () => {
  const [submenuVisibility, setSubmenuVisibility] = useState({});
  const rightRefs = useRef({});
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    const getMenuItem = async () => {
      try {
        const menu = localStorage.getItem('menuItem'); //await cookies.get('menuItem');
        setMenuData(JSON.parse(menu) || []);
      } catch (e) {
        console.error('Error getting menu item: ' + e);
      }
    };
  
    getMenuItem();
  }, []);

    const toggleSubMenu = (index) => {
        console.log(index);
        setSubmenuVisibility((prev) => ({ ...prev, [index]: !prev[index] }));
    };
    const clientID = localStorage.getItem('id');
    return (
       
        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
            <div className="app-brand demo">
                <Link to="#" className="app-brand-link">
                    <img src={process.env.PUBLIC_URL + '/logo.jpg'} style={{width:'130px', borderRadius:"5px"}}/>
                </Link>
                <Link to="#" className="layout-menu-toggle menu-link text-large ms-auto">
                    <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
                    <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
                </Link>
            </div>
            <div className="menu-inner-shadow"></div>
                <ul className="menu-inner py-1">
                    {menuData.map((valueM, index) => (
                        
                <li
                    key={index}
                    className={`menu-item ${submenuVisibility[index] ? 'open' : ''}`}
                >
                    <Link
                        to="#"
                        className="menu-link menu-toggle"
                        onClick={() => toggleSubMenu(index)}
                        style={{ textDecoration: 'none' }}
                    >
                        <i className={valueM.parentMenu.product_icon}></i>
                        <div data-i18n={valueM.parentMenu.name}>{valueM.parentMenu.name}</div>
                    </Link>
                    <ul
                        className="menu-sub"
                        ref={(ref) => (rightRefs.current[index] = ref)}
                        style={{ display: submenuVisibility[index] ? 'flex' : 'none' }}
                    >
                        {valueM.parentMenu.submenu.map((childMenu) => (
                            <li key={childMenu.id} className="menu-item">
                                <Link
                                    to={`/${childMenu.product_link}`}
                                    className="menu-link"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <div data-i18n={childMenu.name}>{childMenu.name}</div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
            ))}
            {(clientID == 6 || clientID == 7 || clientID == 1) && (
                <li key={900}
                className={`menu-item ${submenuVisibility[900] ? 'open' : ''}`}>
                    <Link
                        to="#"
                        className="menu-link menu-toggle"
                        onClick={() => toggleSubMenu(900)}
                        style={{ textDecoration: 'none' }}
                    >
                        <i className="menu-icon tf-icons ti ti-upload"></i>
                        <div data-i18n="Manage Bulk">Manage Bulk</div>
                    </Link>
                    <ul 
                        className="menu-sub"
                        ref={(ref) => (rightRefs.current[900] = ref)}
                        style={{ display: submenuVisibility[900] ? 'flex' : 'none' }}
                        >
                        <li className="menu-item">
                            <Link
                                className="menu-link"
                                to="/manage-import-bulk"
                                style={{ textDecoration: 'none' }}
                            >
                                Import Bulk
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                className="menu-link"
                                to="/manage-export-bulk"
                                style={{ textDecoration: 'none' }}
                            >Export Bulk</Link>
                        </li>
                    </ul>
                </li>
            )}
                </ul>
        </aside>
        
    );
}

export default Sidebar;