import React, { useState, useRef } from "react";
import moment from 'moment';
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import Sidebar from "../common/Sidebar";
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";
import "../../Pan.css";


const Pan = () => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    const [rc, setRc] = useState("");
    const [rcDetails, setRcDetails] = useState(null);
    const [openIndex, setOpenIndex] = useState(null);

    const handleCancel = () => {
        formRef.current.reset();
        setRc("");
        setRcDetails(null);
        setOpenIndex(null);
    };

    const rcFormSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const data = { request_rc_number: rc };

        CommonService.rcSearchHyundaiHis(data)
            .then((response) => {
                if (response.data.status === 200) {
                    setRcDetails(response.data.data);
                } else {
                    Swal.fire({ icon: 'error', title: response.data.data.status, text: response.data.message });
                }
                setIsLoading(false);
            })
            .catch((error) => {
                if(error.response.data.status == 500){
                    Swal.fire({ icon: 'error', title: error.response.data.status, text: error.response.data.data });
                    setIsLoading(false);
                    setRcDetails(null);
                }else{
                    Swal.fire({ icon: 'error', title: error.response.data.data.status, text: error.response.data.message });
                    setIsLoading(false);
                    setRcDetails(null);
                }
            });
    };

    const toggleCollapsible = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Sidebar />
                    <div className="layout-page">
                        <Navigation />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card h-100">
                                            <div className="card-body">
                                                <div className="row">
                                                    <h5 className="card-header">Hyundai Service History Report</h5>
                                                    <hr/>
                                                </div>
                                                {isLoading ? (
                                                    <div className="d-flex justify-content-center">
                                                        <div className="sk-fold sk-primary">
                                                            <div className="sk-fold-cube"></div>
                                                            <div className="sk-fold-cube"></div>
                                                            <div className="sk-fold-cube"></div>
                                                            <div className="sk-fold-cube"></div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <form className="card-body" ref={formRef} style={{paddingTop:"0px", paddingLeft:"0px"}} onSubmit={rcFormSubmit}>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label className="form-label">RC No.</label>
                                                                <input
                                                                    type="text"
                                                                    value={rc}
                                                                    onChange={(e) => setRc(e.target.value.toUpperCase())}
                                                                    required
                                                                    className="form-control"
                                                                    placeholder="Enter RC number"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="pt-4">
                                                            <button type="submit" className="btn btn-primary me-sm-3 me-1">Find Status</button>
                                                            <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                                        </div>
                                                    </form>
                                                )}
                                                
                                                {rcDetails && (
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <div id="recordsContainer">
                                                                {rcDetails.data.map((valueT, index) => (
                                                                    <div key={index} className="record">
                                                                        <button 
                                                                            className="collapsible" 
                                                                            onClick={() => toggleCollapsible(index)}
                                                                        >
                                                                            Record {index + 1}: {moment(valueT.dateOfSVC, 'DD/MM/YYYY').format('MMM Do YYYY')} - {valueT.dealerName}
                                                                            <i className={`fa ${openIndex === index ? 'ti ti-caret-up' : 'ti ti-caret-down'}`} style={{ marginLeft: '10px', float:'right' }}></i>
                                                                        </button>
                                                                        <div className={`content ${openIndex === index ? 'show' : ''}`}>
                                                                            <div className="card-body"  style={{paddingTop:"20px"}}>
                                                                                <div className="row"   style={{background: '#ebe9e9'}}>
                                                                                    <div className="col-md-6">
                                                                                        <b>Service Date</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                                        {valueT.dateOfSVC ? valueT.dateOfSVC : 'NA'}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <b>Service Type</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                                        {valueT.serviceType ? valueT.serviceType : 'NA'}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row"   style={{background: '#ebe9e9'}}>
                                                                                    <div className="col-md-6">
                                                                                        <b>Dealer Name</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                                        {valueT.dealerName ? valueT.dealerName : 'NA'}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <b>Dealer No</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                                        {valueT.dealerNo ? valueT.dealerNo : 'NA'}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row"   style={{background: '#ebe9e9'}}>
                                                                                    <div className="col-md-6">
                                                                                        <b>Mileage</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                                        {valueT.mileAge ? valueT.mileAge : 'NA'}
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <b>No. Of Ro</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                                        {valueT.noOfRo ? valueT.noOfRo : 'NA'}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row"  style={{background: '#ebe9e9'}}>
                                                                                    <div className="col-md-6">
                                                                                        <b>Total Amount</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                                    <i className="ti ti-currency-rupee" style={{fontSize:"18px"}}></i>{valueT.totalAmmount ? valueT.totalAmmount : 'NA'}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <b>Payment Type</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                                        {valueT.typeOfPayment ? valueT.typeOfPayment : 'NA'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>    
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>    
                                                        <div className="col-md-5" style={{marginTop: "10px"}}>    
                                                        
                                                                <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                                    <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-clock-12"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 0 0 9 9m9 -9a9 9 0 1 0 -18 0" /><path d="M12 7v5l.5 .5" /><path d="M18 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2" /><path d="M15 21v-6" /></svg> &nbsp;  API HEALTH CHECK
                                                                    </p>
                                                                </ul>
                                                                <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                    <div className="row"  style={{background: '#ebe9e9'}}>
                                                                        <div className="col-md-6">
                                                                            <b> Request Time</b>
                                                                        </div>
                                                                        <div className="col-md-6" style={{textAlign:"right"}}>
                                                                            {rcDetails.requestTimestamp}
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <b>Response Time</b>
                                                                        </div>
                                                                        <div className="col-md-6" style={{textAlign:"right"}}>
                                                                            {rcDetails.responseTimestamp}
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="row"  style={{background: '#ebe9e9'}}>
                                                                        <div className="col-md-6">
                                                                            <b>Latency(sec)</b> 
                                                                        </div>
                                                                        <div className="col-md-6" style={{textAlign:"right"}}>
                                                                            {rcDetails.totalTime}
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                        </div>
                                                    </div>
                                                            
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Pan;
