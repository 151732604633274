import React, { useState, useEffect,useRef } from "react";
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import Sidebar from "../common/Sidebar";
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";
import moment from 'moment';


const Pan = () => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [pan, setPan] = useState("");
    const [mobile, setMobile] = useState("");
    const [rcDetails, setRcDetails] = useState();
    const handleCancel = () => {
        formRef.current.reset();
        setFirst("");
        setLast("");
        setPan("");
        setMobile("");
        setRcDetails(null);
    }

    const rcFormSubmit = (event) =>{
        setIsLoading(true);
        var data = {
            "mobileNo": mobile,
            "firstName": first,
            "lastName":last,
            "pan": pan
        };
        CommonService.creditSearchScoreDetails(data)
        .then((response) => {
            if(response.data.status === 200){
                if(response.data.data.data.data.result_code !== 101){
                    Swal.fire({
                        icon: 'error',
                        title: response.data.data.data.data.result_code,
                        text: response.data.data.data.data.message,
                    })
                    setIsLoading(false);
                    setRcDetails(null);
                }else{
                    setRcDetails(response.data.data.data.data.result);
                    setIsLoading(false);
                }
                
            }else{
                Swal.fire({
                    icon: 'error',
                    title: response.data.status,
                    text: response.data.message,
                })
                setIsLoading(false);
                setRcDetails(null);
            }
        })
        .catch((error) => {
            console.log('An error occurred:', error.response.data.data);
            if(error.response.data.status === 510){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.data,
                })
                setIsLoading(false);
                setRcDetails(null);
            }else if(error.response.data.status ==400){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.data,
                })
                setIsLoading(false);
                setRcDetails(null);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.message,
                })
                setIsLoading(false);
                setRcDetails(null);
            }
        });
    }
    
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar/>
                <div className="layout-page">
                    <Navigation/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-body"> 
                                            <div className="row">
                                                <h5 className="card-header">Credit Score Report</h5>
                                                <hr/>
                                            </div>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="sk-fold sk-primary">
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                    </div>
                                                </div>
                                            ) : ( 
                                            <form className="card-body" ref={formRef} style={{paddingTop:"0px", paddingLeft:"0px"}} data-parsley-validate="" onSubmit={ rcFormSubmit }>
                                                <div className="row">    
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">First Name</label>
                                                        <input type="text" defaultValue={first}
                                                            onChange={(e) => { setFirst(e.target.value) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter first name"
                                                         />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Last Name</label>
                                                        <input type="text" defaultValue={last}
                                                            onChange={(e) => { setLast(e.target.value) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter last name"
                                                         />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Mobile Number</label>
                                                        <input
                                                            type="number"
                                                            value={mobile}
                                                            onChange={(e) => {
                                                                const value = e.target.value.slice(0, 10);
                                                                setMobile(value);
                                                            }}
                                                            required
                                                            data-parsley-required="true"
                                                            maxLength={10} 
                                                            className="form-control"
                                                            placeholder="Enter Mobile"
                                                        />
                                                    </div> 
                                                </div>
                                                <div className="row" style={{marginTop:"15px"}}>   
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Pan Number</label>
                                                        <input type="text" defaultValue={pan}
                                                            onChange={(e) => { setPan(e.target.value.toUpperCase()) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter Pan Number"
                                                         />
                                                    </div>
                                                </div>
                                               <div className="pt-4"  style={{marginTop:"15px"}}>
                                                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Find Status</button>
                                                    <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                                </div>
                                            </form> 
                                            )} 
                                            
                                            { rcDetails ? (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> CREDIT SCORE DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {Object.entries(rcDetails.result_json.INProfileResponse.Header).map(([key, value], index) => {
                                                                    let displayValue = value;

                                                                    if (key === 'ReportDate' && value) {
                                                                        displayValue = moment(value, "YYYYMMDD").format("MMMM D, YYYY");
                                                                    }
                                                                    if (key === 'ReportTime' && value) {
                                                                        displayValue = moment(value, "HHmmss").format("hh:mm:ss A");
                                                                    }

                                                                    return (
                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                            <div className="col-md-6">
                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                            </div>
                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                {displayValue ? displayValue : 'NA'}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> CREDIT PROFILE DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {Object.entries(rcDetails.result_json.INProfileResponse.CreditProfileHeader).map(([key, value], index) => {
                                                                    let displayValue = value;

                                                                    if (key === 'ReportDate' && value) {
                                                                        displayValue = moment(value, "YYYYMMDD").format("MMMM D, YYYY");
                                                                    }
                                                                    if (key === 'ReportTime' && value) {
                                                                        displayValue = moment(value, "HHmmss").format("hh:mm:ss A");
                                                                    }

                                                                    return (
                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                            <div className="col-md-6">
                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                            </div>
                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                {displayValue ? displayValue : 'NA'}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> APPLICATION DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                <div className="row" style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                        <b>Enquiry Reason</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {rcDetails.result_json.INProfileResponse.Current_Application.Current_Application_Details.Enquiry_Reason ? rcDetails.result_json.INProfileResponse.Current_Application.Current_Application_Details.Enquiry_Reason:'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Finance Purpose</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {rcDetails.result_json.INProfileResponse.Current_Application.Current_Application_Details.Finance_Purpose ? rcDetails.result_json.INProfileResponse.Current_Application.Current_Application_Details.Finance_Purpose:'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                        <b>Amount Financed</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {rcDetails.result_json.INProfileResponse.Current_Application.Current_Application_Details.Amount_Financed ? rcDetails.result_json.INProfileResponse.Current_Application.Current_Application_Details.Amount_Financed:'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Duration Of Agreement</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {rcDetails.result_json.INProfileResponse.Current_Application.Current_Application_Details.Duration_Of_Agreement ? rcDetails.result_json.INProfileResponse.Current_Application.Current_Application_Details.Duration_Of_Agreement:'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                        <b>Current Applicant Additional AddressDetails</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {rcDetails.result_json.INProfileResponse.Current_Application.Current_Application_Details.Current_Applicant_Additional_AddressDetails ? rcDetails.result_json.INProfileResponse.Current_Application.Current_Application_Details.Current_Applicant_Additional_AddressDetails:'NA'}
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> APPLICANT DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {Object.entries(rcDetails.result_json.INProfileResponse.Current_Application.Current_Application_Details.Current_Applicant_Details).map(([key, value], index) => {
                                                                    let displayValue = value;

                                                                    if (key === 'ReportDate' && value) {
                                                                        displayValue = moment(value, "YYYYMMDD").format("MMMM D, YYYY");
                                                                    }
                                                                    if (key === 'ReportTime' && value) {
                                                                        displayValue = moment(value, "HHmmss").format("hh:mm:ss A");
                                                                    }

                                                                    return (
                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                            <div className="col-md-6">
                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                            </div>
                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                {displayValue ? displayValue : 'NA'}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div> 
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> APPLICANT OTHER DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {Object.entries(rcDetails.result_json.INProfileResponse.Current_Application.Current_Application_Details.Current_Other_Details).map(([key, value], index) => {
                                                                    let displayValue = value;

                                                                    if (key === 'ReportDate' && value) {
                                                                        displayValue = moment(value, "YYYYMMDD").format("MMMM D, YYYY");
                                                                    }
                                                                    if (key === 'ReportTime' && value) {
                                                                        displayValue = moment(value, "HHmmss").format("hh:mm:ss A");
                                                                    }

                                                                    return (
                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                            <div className="col-md-6">
                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                            </div>
                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                {displayValue ? displayValue : 'NA'}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div> 
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> APPLICANT ADDRESS DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {rcDetails.result_json.INProfileResponse.Current_Application.Current_Application_Details.Current_Applicant_Address_Details.map((addressDetails, addressIndex) => (
                                                                    <div key={`address-${addressIndex}`}>
                                                                        {Object.entries(addressDetails).map(([key, value], index) => {
                                                                            let displayValue;

                                                                            if (value === null || value === undefined) {
                                                                                displayValue = 'NA';
                                                                            } else if (typeof value === 'object') {
                                                                                displayValue = JSON.stringify(value);
                                                                                displayValue = value;
                                                                            }

                                                                            if (key === 'Country_Code' && value === 'IB') {
                                                                                displayValue = 'India';
                                                                            }

                                                                            return (
                                                                                <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                                    <div className="col-md-6">
                                                                                        <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                        {displayValue}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                ))}
                                                            </div> 
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> CAIS HOLDER DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {rcDetails?.data?.result_json?.INProfileResponse?.CAIS_Account?.CAIS_Account_DETAILS?.length > 0 ? (
                                                                    rcDetails.result_json.INProfileResponse.CAIS_Account.CAIS_Account_DETAILS.map((accountDetail, detailIndex) => (
                                                                        <div key={`account-detail-${detailIndex}`}>
                                                                            {accountDetail?.CAIS_Holder_Details?.map((holderDetail, holderIndex) => (
                                                                                <div key={`history-${holderIndex}`}>
                                                                                    {Object.entries(holderDetail).map(([key, value], index) => (
                                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                                            <div className="col-md-6">
                                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                                            </div>
                                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                                {value !== null ? value : "N/A"}
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <div>No data available</div>
                                                                )}
                                                            </div> 
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> CAIS HOLDER ADDRESS DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {rcDetails?.data?.result_json?.INProfileResponse?.CAIS_Account?.CAIS_Account_DETAILS?.length > 0 ? (
                                                                    rcDetails.result_json.INProfileResponse.CAIS_Account.CAIS_Account_DETAILS.map((accountDetail, detailIndex) => (
                                                                        <div key={`account-detail-${detailIndex}`}>
                                                                            {accountDetail?.CAIS_Holder_Address_Details?.map((addressDetail, addressIndex) => (
                                                                                <div key={`history-${addressIndex}`}>
                                                                                    {Object.entries(addressDetail).map(([key, value], index) => (
                                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                                            <div className="col-md-6">
                                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                                            </div>
                                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                                {value !== null ? value : "N/A"}
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <div>No data available</div>
                                                                )}
                                                            </div> 
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> MATCH RESULT
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {Object.entries(rcDetails.result_json.INProfileResponse.Match_result).map(([key, value], index) => {
                                                                    return (
                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                            <div className="col-md-6">
                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                            </div>
                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                {value ? value : 'NA'}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> Total CAPS SUMMARY
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {Object.entries(rcDetails.result_json.INProfileResponse.TotalCAPS_Summary).map(([key, value], index) => {
                                                                    return (
                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                            <div className="col-md-6">
                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                            </div>
                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                {value ? value : 'NA'}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> TOTAL CAPS SUMMARY
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {Object.entries(rcDetails.result_json.INProfileResponse.CAPS.CAPS_Summary).map(([key, value], index) => {
                                                                    return (
                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                            <div className="col-md-6">
                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                            </div>
                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                {value !== null && value !== undefined ? value : 'NA'}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> NON CREDIT CAPS SUMMARY
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {Object.entries(rcDetails.result_json.INProfileResponse.NonCreditCAPS.NonCreditCAPS_Summary).map(([key, value], index) => {
                                                                    return (
                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                            <div className="col-md-6">
                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                            </div>
                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                {value !== null && value !== undefined ? value : 'NA'}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> CREDIT ACCOUNT DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {Object.entries(rcDetails.result_json.INProfileResponse.CAIS_Account.CAIS_Summary.Credit_Account).map(([key, value], index) => {
                                                                    let displayValue = value;

                                                                    if (key === 'ReportDate' && value) {
                                                                        displayValue = moment(value, "YYYYMMDD").format("MMMM D, YYYY");
                                                                    }
                                                                    if (key === 'ReportTime' && value) {
                                                                        displayValue = moment(value, "HHmmss").format("hh:mm:ss A");
                                                                    }

                                                                    return (
                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                            <div className="col-md-6">
                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                            </div>
                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                {displayValue ? displayValue : 'NA'}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> TOTAL OUTSTANDING DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {Object.entries(rcDetails.result_json.INProfileResponse.CAIS_Account.CAIS_Summary.Total_Outstanding_Balance).map(([key, value], index) => {
                                                                    let displayValue = value;

                                                                    if (key === 'ReportDate' && value) {
                                                                        displayValue = moment(value, "YYYYMMDD").format("MMMM D, YYYY");
                                                                    }
                                                                    if (key === 'ReportTime' && value) {
                                                                        displayValue = moment(value, "HHmmss").format("hh:mm:ss A");
                                                                    }

                                                                    return (
                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                            <div className="col-md-6">
                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                            </div>
                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                {displayValue ? displayValue : 'NA'}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div> 
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> CAIS ACCOUNT DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            {rcDetails.result_json.INProfileResponse.CAIS_Account.CAIS_Account_DETAILS.map((accountDetails, accountIndex) => (
                                                                <div key={`account-${accountIndex}`}>
                                                                    {Object.entries(accountDetails).map(([key, value], index) => {
                                                                        let displayValue;

                                                                        if (key === 'Open_Date' && value) {
                                                                            displayValue = moment(value, "YYYYMMDD").format("MMMM D, YYYY");
                                                                        } else if (key === 'Date_Reported' && value) {
                                                                            displayValue = moment(value, "YYYYMMDD").format("MMMM D, YYYY");
                                                                        } else if (value === null || value === undefined) {
                                                                            displayValue = 'NA';
                                                                        } else if (typeof value === 'object') {
                                                                            
                                                                        
                                                                        } else {
                                                                            displayValue = value;
                                                                        }

                                                                        return (
                                                                            <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                                <div className="col-md-6">
                                                                                    <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                                </div>
                                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                    {displayValue}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            ))}
                                                            </div> 
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> CAIS ACCOUNT HISTORY
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {rcDetails?.data?.result_json?.INProfileResponse?.CAIS_Account?.CAIS_Account_DETAILS?.length > 0 ? (
                                                                    rcDetails.result_json.INProfileResponse.CAIS_Account.CAIS_Account_DETAILS.map((accountDetail, detailIndex) => (
                                                                        <div key={`account-detail-${detailIndex}`}>
                                                                            {accountDetail?.CAIS_Account_History?.map((historyDetail, historyIndex) => (
                                                                                <div key={`history-${historyIndex}`}>
                                                                                    {Object.entries(historyDetail).map(([key, value], index) => (
                                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                                            <div className="col-md-6">
                                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                                            </div>
                                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                                {value !== null ? value : "N/A"}
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <div>No data available</div>
                                                                )}
                                                            </div> 
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> CAIS HOLDER PHONE DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {rcDetails?.data?.result_json?.INProfileResponse?.CAIS_Account?.CAIS_Account_DETAILS?.length > 0 ? (
                                                                    rcDetails.result_json.INProfileResponse.CAIS_Account.CAIS_Account_DETAILS.map((accountDetail, detailIndex) => (
                                                                        <div key={`account-detail-${detailIndex}`}>
                                                                            {accountDetail?.CAIS_Holder_Phone_Details?.map((phoneDetail, phoneIndex) => (
                                                                                <div key={`history-${phoneIndex}`}>
                                                                                    {Object.entries(phoneDetail).map(([key, value], index) => (
                                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                                            <div className="col-md-6">
                                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                                            </div>
                                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                                {value !== null ? value : "N/A"}
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <div>No data available</div>
                                                                )}
                                                            </div> 
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> CAIS HOLDER ID DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {rcDetails?.data?.result_json?.INProfileResponse?.CAIS_Account?.CAIS_Account_DETAILS?.length > 0 ? (
                                                                    rcDetails.result_json.INProfileResponse.CAIS_Account.CAIS_Account_DETAILS.map((accountDetail, detailIndex) => (
                                                                        <div key={`account-detail-${detailIndex}`}>
                                                                            {accountDetail?.CAIS_Holder_ID_Details?.map((idDetail, idIndex) => (
                                                                                <div key={`history-${idIndex}`}>
                                                                                    {Object.entries(idDetail).map(([key, value], index) => (
                                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                                            <div className="col-md-6">
                                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                                            </div>
                                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                                {value !== null ? value : "N/A"}
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <div>No data available</div>
                                                                )}
                                                            </div> 
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-clock-12"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 0 0 9 9m9 -9a9 9 0 1 0 -18 0" /><path d="M12 7v5l.5 .5" /><path d="M18 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2" /><path d="M15 21v-6" /></svg> &nbsp;  API HEALTH CHECK
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                <div className="row" style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                        <b> Request Time</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {rcDetails.requestTimestamp}
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Response Time</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {rcDetails.responseTimestamp}
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="row" style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                        <b>Latency(sec)</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {rcDetails.totalTime}
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-truck me-1'></i> SCORE
                                                                </p>
                                                            </ul>
                                                            <div className="card-body"  style={{paddingTop:"0px"}}>
                                                                {Object.entries(rcDetails.result_json.INProfileResponse.SCORE).map(([key, value], index) => {
                                                                    return (
                                                                        <div key={key} className="row" style={index % 2 === 0 ? { background: '#ebe9e9' } : {}}>
                                                                            <div className="col-md-6">
                                                                                <b>{key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}</b>
                                                                            </div>
                                                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                {value !== null && value !== undefined ? value : 'NA'}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : ( '' )}   
                                            
                                        </div>
                                    </div>    
                                </div>
                             </div> 
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>    
    </>
    )
}   

export default Pan;